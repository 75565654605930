import { useEffect, useState } from "react";
import { Page, PageStatus } from "src/components";
import { useLoaders } from "src/components/InlineLoader";
import {
  Button,
  Color,
  Flex,
  Pagination,
  Scroller,
  SearchUncontrolled,
  SubgridTable,
  Text,
} from "src/elements";

import { useApi } from "src/api";
import { useModals } from "src/components/Modals";
import { Product } from "@CommonTypes/owa-db/merchantSchema";
import { RoutePutInputsProducts } from "@CommonApi/inputs/products";
import { getProductsTableColumns } from "./store/columns";
import { usePaginationPage } from "src/elements/Pagination";
import { useAsyncState } from "src/hooks/useAsyncState";
import {
  ResponseGetInputsProductsAll,
  RouteGetInputsProductsAll,
} from "@CommonApi/inputs/products/all";

export const PageProducts = () => {
  const { getInputsProductsAllApi, putInputsProductsApi } = useApi();
  const { hasLoader } = useLoaders();
  const { openConfirmModal } = useModals();

  const [page, setPage] = usePaginationPage();
  const [products, setProducts] = useAsyncState<ResponseGetInputsProductsAll>();

  const [modifiedProducts, setModifiedProducts] = useState<{
    [key: string]: Product;
  }>({});

  const isLoading = products === undefined || hasLoader(RouteGetInputsProductsAll);
  const isSaveLoading = hasLoader(RoutePutInputsProducts);

  useEffect(() => {
    loadProducts();
  }, [page]);

  const loadProducts = async (search?: string) => {
    setProducts(await getInputsProductsAllApi({ page, size: 100, search }));
  };

  const saveProducts = async () => {
    const response = await putInputsProductsApi({
      products: Object.values(modifiedProducts),
    });

    if (response) {
      openConfirmModal({
        icon: { name: "checkmarkCircle" },
        title: "Products changes have been saved!",
        buttons: [{ label: "Confirm" }],
      });
      clearModifiedProducts();
      loadProducts();
    }
  };

  const clearModifiedProducts = () => {
    setModifiedProducts({});
  };

  return (
    <Page>
      <Flex column flexGrow gap="10px">
        <Flex padding="10px 0 0 0" justify="between" align="center">
          <Text variant="h3">Products</Text>
        </Flex>

        <Flex justify="between">
          <Flex maxWidth="200px">
            <SearchUncontrolled
              placeholder="Search products"
              onSearch={(search) => loadProducts(search)}
            />
          </Flex>

          <Button width="120px" iconRight={{ name: "plus" }} isDisabled>
            Add Product
          </Button>
        </Flex>

        <Flex column flexGrow gap="20px">
          {isLoading && <PageStatus label="Loading Products..." icon="spinner" />}

          {!isLoading && !products && (
            <PageStatus label="Products not found!" icon="alert" color={Color.red} />
          )}

          {!isLoading && products && (
            <>
              <Flex minHeight="0" flexGrow>
                <Scroller>
                  <SubgridTable
                    columns={getProductsTableColumns({
                      modifiedProducts,
                      setModifiedProducts,
                    })}
                    data={products.data}
                  />
                </Scroller>
              </Flex>

              {Object.keys(modifiedProducts).length > 0 && (
                <Flex justify="right">
                  <Button width="120px" isLoading={isSaveLoading} onClick={saveProducts}>
                    Save Changes
                  </Button>
                </Flex>
              )}
            </>
          )}
        </Flex>

        <Pagination
          page={page}
          setPage={setPage}
          totalPages={products?.totalPages || 1}
          isUsingInput
          isLoading={isLoading}
        />
      </Flex>
    </Page>
  );
};
