import { PlanogramContainer } from "./container";
import { PlanogramPegboard, PlanogramPegboardNonCircular } from "./pegboard";
import { PlanogramShelf, PlanogramShelfNonCircular } from "./shelf";

/**
 * 2.7 - removed hardcoded shelfNo and bayNo
 * 2.6 - unranged items have potential shelves
 * 2.5 - containers inside pog
 * 2.4 - dividerWidth support
 * 2.3 - grillHeight support
 * 2.2 - initial hangcells support
 * 2.1 - first pog with provided version, profit added to PlanogramItem, version added to Pog
 */
export const latestPogVersion = "2.7";

interface PogCommon {
  version: string;
  containers: PlanogramContainer[];
  metrics: {
    returnOnSpace: number | null;
    shelfAlignment: number | null;
    coreRange: number | null;
    dosMos: number | null;
  };
}

export interface Pog extends PogCommon {
  planogram: Planogram;
  unrangedItems: UnrangedPlanogramItem[];
  deletedItems: (PlanogramItem & { storeCodes: string[] })[];
}

export interface PogNonCircular extends PogCommon {
  planogram: PlanogramNonCircular;
  unrangedItems: UnrangedPlanogramItemNonCircular[];
  deletedItems: (PlanogramItemNonCircular & { storeCodes: string[] })[];
}

interface PlanogramCommon {
  width: number;
  height: number;
}

export interface Planogram extends PlanogramCommon {
  bays: PlanogramBay[];
}

export interface PlanogramNonCircular extends PlanogramCommon {
  bays: PlanogramBayNonCircular[];
}

interface PlanogramBayCommon {
  uniqueId: string;
  depth: number;
  baseHeight: number;
  notchOffset: number;
  notchSpacing: number;
  maxNotch: number;
  xLeft: number;
  xRight: number;
  yBottom: number;
  yTop: number;
}

export interface PlanogramBay extends PlanogramBayCommon {
  planogram: Planogram;
  shelves: PlanogramShelf[];
  pegboards: PlanogramPegboard[];
}

export interface PlanogramBayNonCircular extends PlanogramBayCommon {
  shelves: PlanogramShelfNonCircular[];
  pegboards: PlanogramPegboardNonCircular[];
}

export type PlanogramItemOrientation = "FRONT" | "FRONT2" | "SIDE" | "SIDE2" | "TOP" | "TOP2";

export interface PlanogramItemCommon {
  uniqueId: string;
  id: string;
  productCode: string;
  name: string;
  upc: string;
  brand: string;
  variant: string;
  categoryCode: string;
  subCategory: string;
  size: string;
  color: string;
  profit: number | null;
  quantity: number;
  price: number;
  salesAmount: number;
  orientation: PlanogramItemOrientation;
  validOrientations: PlanogramItemOrientation[];
  merchandisingStyle: "UNIT" | "TRAY" | "CASE";
  unitWidth: number;
  unitHeight: number;
  unitDepth: number;
  trayWidth: number;
  trayHeight: number;
  trayDepth: number;
  caseWidth: number;
  caseHeight: number;
  caseDepth: number;
  displayWidth: number;
  displayHeight: number;
  displayDepth: number;
  dividerWidth: number;
  dividerHeight: number;
  noOfUnitsInCase: number;
  unitsCaseWide: number;
  unitsCaseDeep: number;
  unitsCaseHigh: number;
  noOfUnitsInTray: number;
  unitsTrayWide: number;
  unitsTrayDeep: number;
  unitsTrayHigh: number;
  squeezeWidthPct: number;
  squeezeHeightPct: number;
  squeezeDepthPct: number;
  overhangSqueezeDepthFlag: boolean;
  cdt1: string;
  cdt1Priority: number;
  cdt2: string;
  cdt2Priority: number;
  cdt3: string;
  cdt3Priority: number;
  facings: number;
  minFacings: number;
  maxFacings: number;
  facingsRows: number;
  minFacingsRows: number;
  maxFacingsRows: number;
  minOnShelf: number;
  minDaysOfSupply: number;
  maxDaysOfSupply: number;
  zCappingFlag: boolean;
  inCoreRange: boolean;
  newItemFlag: boolean;
  newItemStoreCodes?: string[];
  dndFlag: boolean;
}

export interface PlanogramItem extends PlanogramItemCommon {
  shelf: PlanogramShelf | null;
}

export interface PlanogramItemNonCircular extends PlanogramItemCommon {
  shelf: string | null;
}

export interface UnrangedPlanogramItem extends PlanogramItem {
  potentialShelves: string[];
}

export interface UnrangedPlanogramItemNonCircular extends PlanogramItemNonCircular {
  potentialShelves: string[];
}

export interface TemplatePogData {
  template_id?: string | null;
  total_base_pog?: number;
  is_warning: boolean;
  total_favourite_base_pog?: number;
  base_pog?: {
    base_pog_id: number;
    base_pog_data: PogNonCircular;
    thumbnail: TemplatePogThumbnail;
  };
}

export type TemplatePogThumbnail =
  | {
      status: "IN_PROGRESS" | "ERROR" | "NOT_STARTED";
      url?: never;
    }
  | {
      status: "DONE";
      url: string;
    };

export interface PogData {
  id: number | null;
  pog_data?: PogNonCircular | null;
  is_favourite: boolean;
  solution_id: number;
}
