import { useAtomValue } from "jotai";
import {
  planogramHoveredContainerAtom,
  planogramHoveredItemAtom,
  planogramHoveredShelfAtom,
} from "../store/atoms";
import { Flex } from "src/elements";
import { ItemDetails } from "./components/ItemDetails";
import { ContainerDetails } from "./components/ContainerDetails";
import { ShelfDetails } from "./components/ShelfDetails";

export const PlanogramHovererDetails = () => {
  const hoveredItem = useAtomValue(planogramHoveredItemAtom);
  const hoveredContainer = useAtomValue(planogramHoveredContainerAtom);
  const hoveredShelf = useAtomValue(planogramHoveredShelfAtom);

  return (
    <Flex minHeight="24px" gap="5px" align="center" overflow="hidden">
      {hoveredItem && <ItemDetails item={hoveredItem} />}

      {hoveredContainer && <ContainerDetails container={hoveredContainer} />}

      {hoveredShelf && <ShelfDetails shelf={hoveredShelf} />}
    </Flex>
  );
};
