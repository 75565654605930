import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FiltersSidebar, Page, PageStatus } from "src/components";
import { useLoaders } from "src/components/InlineLoader";
import { Button, Color, Flex, HorizontalDivider, Scroller } from "src/elements";
import styled from "styled-components";

import { ViewAsStoreTemplate } from "src/components/ViewAsStoreTemplate";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";
import {
  PogOverview,
  pogOverviewHeight,
} from "../Merchflows_merchflowId_Stores_storeCode/components/PogOverview";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { RoutePostMerchflows_MerchflowId_ExportsBatch } from "@CommonApi/merchflows/_merchflowId_/exports/batch";
import { useApi } from "src/api";
import { sleep } from "src/utils/sleep";
import {
  ResponseGetMerchflowsTemplateAll,
  RouteGetMerchflowsTemplateAll,
} from "src/api/merchflows/template-all";

import { merchflowTemplatesFilters } from "./store/filters";
import { useFiltersSidebar } from "src/components/FiltersSidebar/store/hooks";
import { useAsyncState } from "src/hooks/useAsyncState";
import { RoutePutBasePogSelectedStatus } from "@CommonApi/pogger/base-pog/status";

const Layout = styled.div<{ isVertical?: boolean }>`
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: ${pogOverviewHeight};
  gap: 20px;
`;

export const PageMerchflowTemplates = () => {
  const { merchflowId: _merchflowId } = useParams<{
    merchflowId: string;
  }>();
  const merchflowId = Number(_merchflowId);

  const { postExportBatchPogsApi, getMerchflowsTemplateAllApi, putBasePogStatusApi } = useApi();
  const { hasLoader } = useLoaders();
  const { navigate } = useNavigation();
  const { openFilters } = useFiltersSidebar();

  const [data, setData] = useAsyncState<ResponseGetMerchflowsTemplateAll>();
  const [filteredData, setFilteredData] = useState(data);

  const [selection, setSelection] = useState<number[]>([]);
  const [isExportingDone, setIsExportingDone] = useState(false);
  const [isFavoriteDone, setIsFavoriteDone] = useState(false);

  const isLoading = hasLoader(RouteGetMerchflowsTemplateAll);
  const isFavoriteLoading = hasLoader(RoutePutBasePogSelectedStatus);
  const isExportLoading = hasLoader(RoutePostMerchflows_MerchflowId_ExportsBatch);

  useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = async () => {
    const response = await getMerchflowsTemplateAllApi(merchflowId);
    setData(response);

    if (response) {
      let refresh = false;
      for (const template of response) {
        if (template.base_pog?.thumbnail.status === "IN_PROGRESS") {
          refresh = true;
          break;
        }
      }

      if (refresh) {
        await sleep(10_000);
        loadTemplates();
      }
    }
  };

  const onClickTemplate = (templateId: string) => {
    navigate(
      routeToUrl(pages.merchflowTemplateBasePogs, {
        merchflowId,
        templateId,
      }),
    );
  };
  const onClickFavorite = async () => {
    const response = await putBasePogStatusApi({
      is_favourite: selection,
    });

    if (response) {
      loadTemplates();
      setSelection([]);
      setIsFavoriteDone(true);
      setTimeout(() => {
        setIsFavoriteDone(false);
      }, 1000);
    }
  };

  const onClickUnFavorite = async () => {
    const response = await putBasePogStatusApi({
      is_not_favourite: selection,
    });

    if (response) {
      loadTemplates();
      setSelection([]);
      setIsFavoriteDone(true);
      setTimeout(() => {
        setIsFavoriteDone(false);
      }, 1000);
    }
  };

  const exportSelectedStorePogs = async () => {
    const response = await postExportBatchPogsApi(merchflowId, {
      type: "psa",
      base_pog_ids: selection,
    });

    if (response?.status === "DONE") {
      setIsExportingDone(true);
      setSelection([]);
      sleep(1000);
      setIsExportingDone(false);
    }
  };

  return (
    <Page>
      <MerchflowHeader merchflowId={merchflowId} />
      <Flex margin="10px 0 0 0">
        <Button
          color="greenSmoke"
          iconRight={{ name: "filter" }}
          isDisabled={isLoading}
          onClick={openFilters}
        >
          Filters
        </Button>
      </Flex>
      <Flex minHeight="0" borderColor={Color.lightGrayHover} borderRadius="3px" column flexGrow>
        <Flex gap="10px" justify="between" padding="15px 10px 10px 10px">
          <ViewAsStoreTemplate merchflowId={merchflowId} isTemplate />

          <Flex gap="10px">
            <Button
              isSuccessful={isFavoriteDone}
              isLoading={isFavoriteLoading}
              dropdown={[
                {
                  label: "Favorite selected",
                  onClick: onClickFavorite,
                  isDisabled: selection.length === 0,
                },
                {
                  label: "Un-favorite selected",
                  onClick: onClickUnFavorite,
                  isDisabled: selection.length === 0,
                },
              ]}
            >
              Favorite
            </Button>

            <Button
              isLoading={isExportLoading}
              isSuccessful={isExportingDone}
              dropdown={[
                {
                  label: "Export selected",
                  onClick: exportSelectedStorePogs,
                  isDisabled: selection.length === 0,
                },
              ]}
            >
              Exports
            </Button>
          </Flex>
        </Flex>

        <HorizontalDivider color={Color.lightGrayHover} />

        <Flex width="100%" minHeight="0" flexGrow>
          {!isLoading ? (
            <Scroller>
              <Layout>
                {filteredData?.map((template, i) => (
                  <Flex key={i} width="100%" onClick={() => onClickTemplate(template.template_id!)}>
                    <PogOverview
                      key={i}
                      selection={selection}
                      label={`TemplateID: ${template.template_id}`}
                      badge={template.total_base_pog! > 1 ? template.total_base_pog : undefined}
                      pog={
                        template.base_pog?.thumbnail.status === "ERROR"
                          ? template.base_pog?.base_pog_data
                          : undefined
                      }
                      thumbnail={template.base_pog?.thumbnail}
                      status={template.total_favourite_base_pog! > 1 ? "ERROR" : undefined}
                      selectionId={
                        template.total_base_pog === 1 ? template.base_pog?.base_pog_id! : undefined
                      }
                      setSelection={setSelection}
                      width="100%"
                      height="100%"
                    />
                  </Flex>
                ))}
              </Layout>
            </Scroller>
          ) : (
            <PageStatus label="Loading templates..." icon="spinner" />
          )}
        </Flex>
      </Flex>

      <FiltersSidebar
        filters={merchflowTemplatesFilters}
        data={data || null}
        setFilteredData={setFilteredData}
      />
    </Page>
  );
};
